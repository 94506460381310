.pingprobe-suppression-logs {
    padding: 24px;

    .pingprobe-suppression-logs-content {
        .pingprobe-suppression-logs-action {
            display: flex;
            justify-content: flex-end;
        }
        
        tr > td:nth-child(2) {
            word-break: break-all;
        }

        tr > th:last-child,
        tr > td:nth-child(6) {
            width: 7.25rem;
            padding: 0;
        }

        .parsed-rule {
            color: #0288d1;
        }
    }
}
