.cp-usage-tracker-container {
    padding: 24px;
    width: 100%;
}

.title-text {
    font-size: 1.5rem;
    font-weight: bold;
}

.file-upload-container {
    display: flex;
    align-items: center;

    .upload-control {
        margin-left: 20px;
        display: flex;
        
        .p-button {
            margin-left: 2rem;
            margin-top: .5rem;
        }
    }

    .empty-template-msg {
        padding: 1rem .5rem;
        background-color: #f8f9fa;
    }
    
    .uploaded-file-item {
        display: inline-block;
        width: 50%;
    }

    .p-fileupload {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
    
        .p-fileupload-buttonbar {
            padding: .5rem;
            background-color: transparent;
            border: 0px;
    
            button:nth-child(2) {
                display: none;
            }
        }
    
        .p-fileupload-content {
            min-width: 40px;
            padding: 0;
        }
    }
}


.cp-usage-input {
    font-size: 1.2rem;
    margin: 1.5rem 0;

    th {
        text-align: right;
        width: 208px;
    }

    tr {
        text-align: right;
    }

    td:nth-child(1) {
        text-align: left;
    }

    td {
        width: 25%;

        .p-inputnumber {
            display: inline-block;
            width: 38%;
            margin-top: 20px;
            margin-left: 31%;
            
    
            .p-inputnumber-input {
                width: 100%;
            }
        }

    }
}

.p-inputtext {
    width: 100%;
}

.cp-output-file-list-container {

    .cp-output-file-list-title-text {
        font-size: 1.2rem;
        font-weight: 600;
    }

    tr > th:nth-child(6) {
        width: 84px
    }
}

.p-selection-column {
    width: 60px;
}

.red-border {
    border-color: red;
}

.error-status {
    color: red;
}

td {
    word-wrap: break-word
}

.cp-incident-dialog {
    width: 96%;

    tr > th:nth-child(1) {
        width: 52px
    }

    tr > th:nth-child(2) {
        width: 148px
    }

    tr > th:nth-child(5) {
        width: 108px
    }

    tr > th:nth-child(6) {
        width: 108px
    }

    tr > th:nth-child(9) {
        width: 120px
    }

    .disabled-checkbox {
        background-color: #aeaeae;
        border: #dad7d7;
        border-radius: 4px;
    }

    .cp-incident-dialog-refresh-btn {
        margin: 8px -20px 0 -8px;
        float: right;
    }

    .cp-incident-dialog-datatable {
        display: flex;
        flex-direction: column-reverse;
    }

    .cp-incident-dialog-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        vertical-align: center;
    }
}

.store-id-update-container {

    padding: 24px 0;
    width: 100%;

    .store-id-update-table-container {

        tr > th:nth-child(1) {
            width: 92px;
        }

        tr > th:nth-child(2) {
            width: 92px;
        }

        tr > th:nth-child(3) {
            width: 220px;
        }

        tr > th:nth-child(5) {
            width: 172px;
        }
    }
}

/* Media Queries */

@media screen and (max-width: 960px) {
    
    .cp-usage-input td .p-inputnumber {
        margin-left: 20%;
        width: 50%;
    }

    .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
        padding-right: 70px;
    }

}