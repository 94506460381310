.shelly-replacement-history-container {
    padding: 24px;
    width: 100%;

    .input-field {
        display: flex;
        align-items: center;
        margin: 4px 0;

        label {
            width: 180px;
            align-items: center;
        }

        button {
            margin: 0;
        }
    }

    .input-field > .p-inputtext, .input-field > .p-calendar{
        margin-left: 20px;
        width: 220px;
    }

    button {
        margin: 1rem 0;
    }
}