.create-job-container {
	width: 100%;

	.dropdown-container {
		margin: 1rem 0;

		.usecase-dropdown {
			width: 10rem;
			margin-left: 0.6rem;
		}

		.p-dropdown,
		.p-multiselect {
			width: 12rem;
			margin-left: 1rem;
		}
	}

	.grafana-agent-command-main {
		display: flex;

		.store-container {
			width: 70%;
			max-height: 75vh;
			overflow-y: auto;
			margin-right: 1rem;

			.store-container-top {
				display: flex;
				align-items: flex-end;
				flex-wrap: wrap;

				.update-store-selection {
					margin: 8px 0;

					button {
						font-size: 0.85rem;
						padding: 0.5rem;
					}

					button:nth-child(2) {
						margin-left: 1rem;
					}

					.p-inputtext {
						width: 10rem;
						margin-left: 2rem;
					}

					.search-store {
						margin-left: 20rem;
						display: inline;
					}
				}

				.legend-container {
					margin-left: auto;
					margin-right: 44px;
					margin-bottom: 4px;

					.legend {
						display: inline-block;
						width: 12px;
						height: 12px;
						margin: 0 4px 0 8px;
					}
				}
			}
		}

		&.collapsed .store-container {
			width: 100% !important;
			transition: width 0.5s;
		}

		&.collapsed
			.store-container
			.store-container-top
			.update-store-selection
			.search-store {
			width: 100% !important;
			margin: 0 !important;
		}

		.model-container {
			.service-dropdown {
				padding: 15px 15px 15px 0;
				margin-right: 0.3rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			width: 30%;
			max-height: 65vh;
			overflow-y: auto;

			table {
				width: 100%;
				margin-bottom: 1rem;

				tr {
					border-bottom: 1px solid #c1c1c1;
				}

				th,
				td {
					padding: 8px 8px 8px 0;
				}

				td {
					font-size: 0.8rem;

					p-radiobutton {
						margin-left: 1rem;
						height: 10px !important;
						width: 10px !important;
					}

					p-radiobutton-box {
						width: 10px !important;
						height: 10px !important;
						display: flex;
						justify-content: space-between !important;
					}

					a p-radiobutton-icon {
						width: 10px !important;
						height: 10px !important;
					}

					label {
						display: inline !important;
						font-size: 0.8rem;
						font-weight: bold;
						margin-top: 4px;
						margin-left: 0.5rem;
					}
				}
			}
		}
	}

	.submit-btn {
		width: 188px;
		margin-top: 1rem;
		margin-left: calc(50% - 94px);
	}
}

.schedule {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.legend-link {
	margin-left: 200px;
	text-decoration: underline;
	font-size: medium;
	font-weight: bold;
}

.store-btn {
	margin: 0.1rem;
	padding: 0.29rem 0.7rem;
	font-size: 0.7rem;
}

.default-servers {
	background: #8fa2f6 !important;
	border: 1px solid #ece0f5;
	color: white;
	font-weight: bold;
	margin: 0.1rem;
	padding: 0.29rem 0.4rem;

	font-size: 14px;
}

.included-servers {
	background: rgb(90, 131, 236) !important;
}

.excluded-servers {
	background-color: rgb(154, 152, 152) !important;
	opacity: 0.9 !important;
}

.enabled-store {
	background: #22c55e !important;
	border: 1px solid #22c55e !important;
}

.default-store {
	background: #aec2e7 !important;
	border: 1px solid #a1a3a7 !important;
	color: blue;
	font-weight: bold;
}

.highlighted-searched-store {
	border: 3px solid #3117b4 !important;
	color: white !important;
}

.selected-server {
	background-color: rgba(9, 66, 11, 0.792) !important;
	font-style: bolder;
	color: whitesmoke;
}

.collapsed {
	width: 100%;
	overflow: hidden;
	transition: width 0.5s;
}

.expanded {
	width: 30%;
	transition: width 0.5s;
}

.grafana-agent-command-main.collapsed .model-container {
	display: none;
}

.collapse-button {
	position: fixed;
	top: 48%;
	right: 0;
	z-index: 9999;
}

.grafana-agent-command-main.collapsed .collapse-button {
	position: fixed;
	top: 48%;
	right: 0;
	z-index: 9999;
}

.model-container.collapsed .collapse-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9999;
}

.color-legend {
	font-weight: 400;

	.store-legend {
		margin-bottom: 20px;
	}

	.stores-legend {
		width: 12px;
		height: 12px;
		margin-right: 5px;
		display: inline-block;
		background-color: #8fa2f6 !important;
		// background: #8fa2f6 !important;
	}

	.selected-store-legend {
		width: 12px;
		height: 12px;
		margin-left: 20px;
		margin-right: 5px;
		display: inline-block;
		background-color: #22c55e !important;
	}

	.available-servers-legend {
		width: 12px;
		height: 12px;
		// margin-left: 20px;
		margin-right: 5px;
		display: inline-block;
		background-color: rgb(90, 131, 236) !important;
	}

	.unavailable-servers-legend {
		width: 12px;
		height: 12px;
		margin-left: 20px;
		margin-right: 5px;
		display: inline-block;
		background-color: rgb(154, 152, 152) !important;
	}

	.selected-server-legend {
		width: 12px;
		height: 12px;
		margin-left: 20px;
		margin-right: 5px;
		display: inline-block;
		background-color: rgba(9, 66, 11, 0.792) !important;
	}
}

/* Media Queries */

@media screen and (max-width: 960px) {
	.incident-config-container .dropdown-container {
		flex-direction: column;
		align-items: flex-start;
	}

	.incident-config-container .dropdown-container .p-dropdown {
		margin-bottom: 1rem;
		margin-left: 7rem;
	}

	.incident-config-container .dropdown-container .usecase-dropdown {
		margin: 0;
	}

	.incident-config-container .grafana-agent-command-main {
		display: inline-block;
	}

	.incident-config-container .grafana-agent-command-main .store-container {
		width: auto;
		margin-bottom: 1rem;
	}

	.create-job-container .grafana-agent-command-main .model-container {
		width: auto;
		margin-right: 1rem;
		margin-top: 1rem;
	}
}
