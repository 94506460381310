.app {
  width: 100%;
  height: 100vh;
}

.app-header {
  background-color: #ffffff;
  min-height: 20px;
  padding: 10px;
  border-bottom: 2px solid #e6e8f3;
}

.page-title {
  font-weight: 600;
  font-size: 1rem;
  color: #343A40;
}

.divider-line {
  border: 1px solid #2b2c30;
  margin: 0 20px;
  transform: rotate(90deg);
}

.app-header > .divider-line {
  margin: 0 10px 0 4px;
  border: 1px solid #cbe5ee;
}

.user-icon {
  float: right;
  margin-right: 15px;
  margin-top: 7px;
}

.page-label {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: #00529F;

  i{
    margin: 0 4px;
    font-size: 14px;
  } 
}

.page-label-devices {

  display: flex;
  align-items: center;
  font-weight: 700;
  color: #00529F;

    i{
      margin: 0 4px;
      font-size: 14px;
    }
}

.dropdown {
  display: inline;

  button,
  button:hover,
  button:active {
    background: none;
    border: none;
  }
}

.dropdown-small-screens {
  display: none;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.user-name {
  color: #000000;
  padding: 0 10px;
  font-size: 12px;
}

.p-button {
  background: #00529f;
  border: 1px solid #00529f;
}

.p-datatable {
  th {
    background-color: #E1F7FF !important;
    color: #00315E !important;
  }

  .p-sortable-column-icon.pi-sort-alt,
  .p-column-filter-menu-button {
    color: #00315E !important;
  }
    
}


.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #00315e;
  color: #e3f2fd;
}

/* Media Queries */

@media screen and (max-width: 960px) {
  .dropdown-large-screens {
    display: none;
  }

  .dropdown-small-screens {
    display: inline-block;
  }
}
