.loader{
    background-color: #c4c4c45e;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: all;
    z-index: 1120;

    img{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 80px;
        height: 80px;
        margin: auto;
    }
}