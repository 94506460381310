.service-main-logs-container {
  padding: 18px ;
  width: 100%;

  .title {
    padding-bottom: 10px;
    font-weight: 700;
  }

  .service-main-logs-action {
    margin-bottom: 16px;

    .input-field {
      display: flex;
      align-items: center;
      margin: 4px 0;

      label {
        width: 180px;
        align-items: center;
      }

      .p-multiselect,
      .p-calendar {
        margin-left: 20px;
        width: 248px;
      }
    }
  }
  tr > th,
    tr > td {
        padding: 0.75rem !important;
    }

    tr > th:nth-child(1),
    tr > th:nth-child(2) {
        max-width: 108px;
    }

    tr > th:nth-child(5) {
        max-width: 132px;
    }

    tr > th:nth-child(4),
    tr > th:nth-child(7),
    tr > th:nth-child(10) {
        max-width: 100px;
    }

    tr > th:last-child {
        max-width: 60px;
    }
    tr > td:last-child {
        text-align: center;
    }

}