.device-onboarding-container {
    padding: 1.5rem;

    .row {
        padding: 0.25rem 0;

        .col-lg-2 {
            align-self: center;
        }

        .input {
            width: 100%;
        }
    }

    hr {
        margin: 1.5rem 0;
    }

    .device-onboarding-log-container {
        & > button.p-button-text {
            float: right;
            z-index: 10;
        }

        tr > th:nth-child(5) {
            width: 76px;
        }
        tr > th:nth-child(6) {
            width: 85px;
        }

        td:nth-child(5),
        td:nth-child(6) {
            text-align: center;
        }
    }
}

.bulk-upload {
    background: #f2f6f9;
    border-radius: 4px;
    margin-top: 50px;
}

.bulk-upload-button {
    color: #2196f3;
    width: 100%;
    padding: 40px 20px;
    font-size: 14px;
    text-align: center;
}

.bulk-upload-button img {
    width: 40px;
    height: 20px;
    margin-right: 10px;
}

.or-text {
    color: black;
    display: block;
    padding-bottom: 10px;
}

.upload-file-button {
    height: 30px;
    font-size: 14px;
}

.bulk-upload-success {
    padding: 20px 0 0 50px;
}

.bulk-upload-success > div {
    font-weight: bold;
}

.bulk-upload-success > span {
    font-size: 14px;
}

.error {
    color: red;
    padding: 8px 20px;
}

.url-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .success-url {
        color: green;
        text-decoration: none;
        margin: 10px 0 0 20px;
    }

    .success-url:hover {
        text-decoration: underline;
    }

    .download-template {
        margin: 10px 25px 0 0;
    }
}
