.pingprobe-liveping-container {
    padding: 1.5rem;

}
 
.live-ping-btn{
    display: flex;
    justify-content: flex-start;  
    
    .p-button {
        margin-right: 10px;
    }

}
