.pingprobe-suppression-container {
    padding: 1.5rem;

    form {
        .input-field {
            label {
                font-weight: 500;
                line-height: 2rem;
            }

            & > div {
                padding: 0;
                margin-top: 0.25rem;
            }

            & > div:nth-child(2) {
                display: flex;
                gap: 1.5rem;
                margin-bottom: 0.75rem;

                .p-component.p-radiobutton {
                    margin-right: 0.25rem;
                }

                .p-component.p-dropdown {
                    width: 8.5rem;
                    height: 2.5rem;
                }

                .p-component.p-inputtext {
                    max-width: 25rem;
                    height: 2.5rem;
                }

                strong {
                    font-size: 0.875rem;
                    color: #7f7f7f;
                }
            }

            & > button {
                margin-right: 1.25rem;
            }
        }

        & > ul {
            padding: 0;

            li {
                padding: 0;
                list-style: none;
                font-size: 0.875rem;
            }
        }
    }
}
