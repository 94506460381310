.shelly-firmware-container {
	padding: 0rem;

	form {
		.group-field {
			display: flex;
			align-items: center;

			& > div {
				width: 48%;
			}

			& > div:nth-child(2) {
				display: flex;
				gap: 1.5rem;
				margin-bottom: 0.75rem;

				.p-component.p-inputtext {
					max-width: 29rem;
					height: 3rem;
					border-radius: 5px;
				}
			}
		}

		.store-field {
			display: flex;
			align-items: center;

			& > div {
				width: 48%;
			}

			& > div:nth-child(2) {
				display: flex;
				gap: 1.5rem;
				margin-bottom: 0.75rem;

				.p-inputtextarea {
					max-width: 29rem;
					border-radius: 5px;
				}

				strong {
					font-size: 0.875rem;
					color: #7f7f7f;
				}
			}
		}
		.create-group-btn {
			margin-left: 0.1rem;
		}
		.p-button.p-button-danger {
			margin-right: 10px;
		}
	}
}
.disabled-input {
	opacity: 1;
	color: gray;
	font-weight: bold;
}
.modify-impact-note {
	padding-left: 182px;
	margin-bottom: 10px;
}
.modify-impact-note small {
	color: red;
}
