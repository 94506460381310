@mixin table-action-btns {
    display: flex;
    flex-direction: row-reverse;
    gap: 0.5rem;
}

.pingprobe-inventory-container {
    padding: 24px;

    .pingprobe-inventory-action {
        width: 100%;
        margin-bottom: 16px;

        .input-field {
            display: flex;
            align-items: center;
            margin: 4px 0;

            label {
                width: 180px;
                align-items: center;
            }
        }

        .input-field > .p-multiselect {
            margin-left: 20px;
            width: 248px;
        }
    }

    .pingprobe-inventory-content {
        .pingprobe-inventory-table-action {
            @include table-action-btns;

            small {
                align-self: center;
                color: #0288d1;
            }
        }

        .pingprobe-inventory-table {
            width: 100%;

            tr > th:last-child {
                width: 2rem;
            }

            tr > th:nth-child(6) {
                width: 220px;
            }

            tr > th:nth-child(7) {
                max-width: 110px;
            }

            td {
                padding: 0.375rem 1rem;
            }

            .active {
                color: #D32F2F;
            }
        }
    }
}

.pingprobe-incident-table-action {
    @include table-action-btns;
}
