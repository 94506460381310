.cc-remaining-usecase-container {
    padding: 24px 0;
    width: 100%;

    .title {
        padding-bottom: 10px;
        font-weight: 700;
    }

    .usecase-type-radio {
        display: inline-flex;
        gap: 0.75rem;
        margin-bottom: 0.75rem;
        margin-right: 2.25rem;
    }

    .summary-action {
        width: 100%;
        margin-bottom: 16px;

        .input-field {
            display: flex;
            align-items: center;
            margin: 4px 0;

            label {
                width: 180px;
                align-items: center;
            }
        }

        .input-field > .p-multiselect,
        .input-field > .p-calendar {
            margin-left: 20px;
            width: 248px;
        }
    }
}

.cr-container {
    display: flex;
    justify-content: space-between;

    i {
        font-weight: 600;
    }
}

.cc-usecase-detail-dialog {
    width: 88%;
    position: relative;


    .back-icon {
        position: absolute;
        right: 64px;
        top:20px;
        }

    .incident-summary-logs-detail-dialog-table-action {
        display: flex;
        justify-content: flex-end;
        }
        
}
