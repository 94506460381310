.create-job-container {
	width: 100%;

	.appcode-dropdown-container {
		// margin: 1rem 0;
		margin-bottom: 1rem;

		.usecase-dropdown {
			width: 10rem;
			margin-left: 0.6rem;
		}

		.p-dropdown {
			width: 12rem;
			margin-top: 4px;
		}
		.p-multiselect {
			width: 12rem;
			margin-left: 1rem;
			margin-top: 4px;
		}
	}

	.grafana-agent-command-main {
		display: flex;

		.vm-container {
			width: 70%;
			max-height: 75vh;
			overflow-y: auto;
			// margin-right: 1rem;

			.p-paginator {
				padding-right: 0;
			}

			.selected-vms {
				margin-bottom: 1rem;
			}

			.vm-container-top {
				// display: flex;
				// align-items: flex-end;
				// flex-wrap: wrap;

				.update-vm-selection {
					margin: 8px 0;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;

					.vm-select-search-container {
						display: flex;
						align-items: center;
						gap: 1rem;
					}

					button {
						font-size: 0.85rem;
						padding: 0.5rem;
					}

					// button:nth-child(2) {
					// 	margin-left: 1rem;
					// }

					.p-inputtext {
						width: 10rem;
						// margin-left: 2rem;
					}

					.search-vm {
						// margin-left: 20rem;
						// display: inline;
						margin-left: auto;
						display: flex;
						align-items: center;
						gap: 1rem;
					}
				}

				.legend-container {
					margin-left: auto;
					margin-right: 44px;
					margin-bottom: 4px;

					.legend {
						display: inline-block;
						width: 12px;
						height: 12px;
						margin: 0 4px 0 8px;
					}
				}
			}
		}

		&.collapsed .vm-grid {
			display: grid;
			grid-template-columns: repeat(auto-fit,minmax(290px,1fr));
			width: 100% !important;
			gap:2px !important;
			justify-items: center;
			align-items: center;
			transition: width 0.5s;
		}

		&.collapsed .vm-container {
			width: 100% !important;
			transition: width 0.5s;
		}

		// &.collapsed
		// 	.vm-container
		// 	.vm-container-top
		// 	.update-vm-selection
		// 	.search-vm {
		// 	width: 100% !important;
		// 	margin: 0 !important;
		// }

		.model-container {
			.service-dropdown {
				padding: 0 0 15px 0;
				// margin-right: 0.3rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			width: 30%;
			max-height: 65vh;
			overflow-y: auto;

			.p-calendar {
				margin-left: 10px;
			}

			table {
				width: 100%;
				margin-bottom: 1rem;

				tr {
					border-bottom: 1px solid #c1c1c1;
				}

				th,
				td {
					padding: 8px 8px 8px 0;
				}

				td {
					font-size: 0.8rem;

					p-radiobutton {
						margin-left: 1rem;
						height: 10px !important;
						width: 10px !important;
					}

					p-radiobutton-box {
						width: 10px !important;
						height: 10px !important;
						display: flex;
						justify-content: space-between !important;
					}

					a p-radiobutton-icon {
						width: 10px !important;
						height: 10px !important;
					}

					label {
						display: inline !important;
						font-size: 0.8rem;
						font-weight: bold;
						margin-top: 4px;
						margin-left: 0.5rem;
					}
				}
			}
		}
	}

	.submit-btn {
		width: 188px;
		margin-top: 1rem;
		margin-left: calc(50% - 94px);
	}
}

.schedule {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.legend-link {
	margin-left: 200px;
	text-decoration: underline;
	font-size: medium;
	font-weight: bold;
}

.store-btn {
	margin: 0.1rem;
	padding: 0.29rem 0.7rem;
	font-size: 0.7rem;
}

.default-servers {
	background: #8fa2f6 !important;
	border: 1px solid #ece0f5;
	color: white;
	font-weight: bold;
	margin: 0.1rem;
	padding: 0.29rem 0.4rem;

	font-size: 14px;
}

.included-servers {
	background: rgb(90, 131, 236) !important;
}

.excluded-servers {
	background-color: rgb(154, 152, 152) !important;
	opacity: 0.9 !important;
}

.enabled-store {
	background: #22c55e !important;
	border: 1px solid #22c55e !important;
}

.default-store {
	background: #aec2e7 !important;
	border: 1px solid #a1a3a7 !important;
	color: blue;
	font-weight: bold;
}

.highlighted-searched-store {
	border: 3px solid #3117b4 !important;
	color: white !important;
}

.selected-server {
	background-color: rgba(9, 66, 11, 0.792) !important;
	font-style: bolder;
	color: whitesmoke;
}

.collapsed {
	width: 100%;
	overflow: hidden;
	transition: width 0.5s;
}

.expanded {
	width: 30%;
	transition: width 0.5s;
}

.grafana-agent-command-main.collapsed .model-container {
	display: none;
}

.collapse-button {
	position: fixed;
	top: 48%;
	right: 0;
	z-index: 9999;
}

.grafana-agent-command-main.collapsed .collapse-button {
	position: fixed;
	top: 48%;
	right: 0;
	z-index: 9999;
}

.model-container.collapsed .collapse-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	z-index: 9999;
}

.color-legend {
	font-weight: 400;

	.store-legend {
		margin-bottom: 20px;
	}

	.stores-legend {
		width: 12px;
		height: 12px;
		margin-right: 5px;
		display: inline-block;
		background-color: #8fa2f6 !important;
		// background: #8fa2f6 !important;
	}

	.selected-store-legend {
		width: 12px;
		height: 12px;
		margin-left: 20px;
		margin-right: 5px;
		display: inline-block;
		background-color: #22c55e !important;
	}

	.available-servers-legend {
		width: 12px;
		height: 12px;
		// margin-left: 20px;
		margin-right: 5px;
		display: inline-block;
		background-color: rgb(90, 131, 236) !important;
	}

	.unavailable-servers-legend {
		width: 12px;
		height: 12px;
		margin-left: 20px;
		margin-right: 5px;
		display: inline-block;
		background-color: rgb(154, 152, 152) !important;
	}

	.selected-server-legend {
		width: 12px;
		height: 12px;
		margin-left: 20px;
		margin-right: 5px;
		display: inline-block;
		background-color: rgba(9, 66, 11, 0.792) !important;
	}
}

.vm-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 VMs per row */
    gap: 4px; /* Spacing between VMs */
	margin: 0;
	justify-items: stretch; /* Stretch items to fill the grid cell */
    align-items: start; /* Align items to the start of each grid cell */
    justify-content: start; /* Align the entire grid to the start of the container */
    align-content: start; /* Align the entire grid to the start of the container */

	button {
		width: 100%;
	}
}

//   .vm-btn.default {
// 	margin: 0.1rem;
// 	padding: 0.29rem 0.7rem;
// 	font-size: 0.8rem;
//     background-color: #a6c1f5; /* Blue for deselected */
//     color: rgb(30, 20, 220);
// }
 
// .vm-btn.highlighted-vm {

//     margin: 0.1rem;

//     padding: 0.29rem 0.7rem;

//     font-size: 0.8rem;

//     background-color: #a6c1f5; /* Keep the same background color as deselected */

//     color: rgb(30, 20, 220);

//     border: 2px solid #00008b; /* Dark blue border */

//     box-shadow: 0px 0px 4px 2px rgba(0, 0, 139, 0.6); /* Add a subtle glow */

//     border-radius: 4px; /* Optional: smooth corners */

// }

// .vm-btn.selected {
// 	margin: 0.1rem;
// 	padding: 0.29rem 0.7rem;
// 	font-size: 0.8rem;
//     background-color: #4caf50; /* Green for selected */
//     color: white;
// }

.vm-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 290px;  // Fixed width
    // height: 36px;  // Fixed height
	width: 274px;  // Fixed width
    height: 38px;  // Fixed height
    margin: 0px;
    padding: 0.25rem 0.5rem;
    //font-size: 0.75rem;
	font-size: 14px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
 
    &.default {
        background-color: #6593e9;
        //color: rgb(30, 20, 220);
		//background-color: #6e9aec;
		color:white;
    }
 
    &.highlighted-vm {
        background-color: #a6c1f5;
        color: rgb(30, 20, 220);
        border: 2px solid #00008b;
        box-shadow: 0px 0px 4px 2px rgba(0, 0, 139, 0.6);
    }
 
    &.selected {
        background-color: #4caf50;
        color: white;
    }
}

 
/* Media Queries */

@media screen and (max-width: 960px) {
	.incident-config-container .dropdown-container {
		flex-direction: column;
		align-items: flex-start;
	}

	.incident-config-container .dropdown-container .p-dropdown {
		margin-bottom: 1rem;
		margin-left: 7rem;
	}

	.incident-config-container .dropdown-container .usecase-dropdown {
		margin: 0;
	}

	.incident-config-container .grafana-agent-command-main {
		display: inline-block;
	}

	.incident-config-container .grafana-agent-command-main .store-container {
		width: auto;
		margin-bottom: 1rem;
	}

	.create-job-container .grafana-agent-command-main .model-container {
		width: auto;
		margin-right: 1rem;
		margin-top: 1rem;
	}
}
