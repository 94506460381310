.shelly-firmware-group-details {
	width: 100%;

	.shelly-firmware-group-details-content {
		.shelly-firmware-group-details-action {
			.input-field {
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}
		}
	}
	.shelly-group-details-action-icons {
		display: flex;
		justify-content: space-between;
	}
	table {
		width: 100%;

		tr > th:nth-child(1) {
			width: 200px;
		}
		tr > th:nth-child(3) {
			width: 200px;
		}

		tr > th:nth-child(4) {
			width: 7.25rem;
		}

		tr > td:nth-child(4) {
			padding: 0;
			text-align: center;
		}
	}
}

.payload-template {
	max-height: 8em;
	padding-right: 8px;
	overflow-y: scroll;
	word-break: break-all;
}

.payload-template::-webkit-scrollbar {
	width: 4px;
}

.payload-template::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.payload-template::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}

.shelly-firmware-group-detail-dialog {
	width: 80%;
	table {
		width: 100%;
		tr > th:nth-child(1) {
			width: 120px;
		}
		tr > th:nth-child(3) {
			width: 140px;
		}
	}
}
