.shelly-firmware-campaign-details {
	width: 100%;
	.shelly-firmware-campaign-details-content {
		.shelly-firmware-campaign-details-action {
			margin-bottom: 16px;
			.input-field {
				display: flex;
				align-items: center;
				margin: 4px 0;

				label {
					width: 80px;
					align-items: center;
				}

				.p-multiselect {
					margin-left: 20px;
					width: 200px;
				}
			}
			.create-campaign-button {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}
	}

	.shelly-campaign-details-action-icons {
		display: flex;
		justify-content: space-between;
	}

	table {
		tr > th,
		tr > td {
			padding: 0.75rem !important;
		}

		tr > th:nth-child(2) {
			max-width: 120px;
		}

		tr > th:nth-child(3),
		tr > th:nth-child(4) {
			max-width: 140px;
		}

		tr > th:nth-child(7) {
			max-width: 130px;
		}

		tr > th:last-child {
			width: 7.25rem;
		}

		tr > td:last-child {
			padding: 0;
			text-align: center;
		}
	}

	td {
		word-wrap: break-word;
	}
}
.shelly-firmware-campaign-container {
	padding: 0rem;

	form {
		.input-field {
			display: flex;
			align-items: center;
			margin: 4px 0;

			label {
				width: 180px;
				align-items: center;
			}

			.p-dropdown {
				margin-left: 20px;
				width: 240px;
			}
		}
		.input-field > .p-inputtext {
			margin-left: 20px;
			width: 280px;
		}
		.input-field > .p-inputswitch {
			margin-left: 20px;
		}
		.p-calendar {
			margin-left: 20px;
			height: 45px;
			width: 240px;
		}
		.p-datepicker-trigger {
			padding: 0;
			margin: 0;
		}
		.p-button.p-button-secondary {
			margin-left: 10px;
		}
		.p-button.p-button-danger {
			margin-right: 10px;
		}
		button {
			margin-top: 8px;
			margin-bottom: 24px;
		}
	}
}

.shelly-firmware-campaign-devices {
	table {
		tr > th:nth-child(1) {
			max-width: 140px;
		}
		tr > th:nth-child(2) {
			max-width: 120px;
		}
		tr > th:nth-child(5),
		tr > th:nth-child(6) {
			max-width: 100px;
		}

		tr > th:nth-child(7) {
			max-width: 80px;
		}
		tr > th:last-child {
			width: 60px;
		}

		tr > td:last-child {
			text-align: center;
		}
	}
	.p-datatable {
		max-height: 20rem;
		min-width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.p-datatable .p-datatable-thead > tr > th {
		background: #9797974d !important;
		border: 1px solid #bbbbbb !important;
		color: #343a40;
		font-size: 11px;
		padding: 10px;
	}

	.p-datatable tr > td {
		color: #444444;
		font-size: 12px;
		border: 1px solid #bbbbbb !important;
		padding: 10px;
	}

	.p-datatable-wrapper::-webkit-scrollbar {
		width: 0.325rem;
	}

	.p-datatable-wrapper::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	.p-datatable-wrapper::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		outline: 1px solid slategrey;
	}
}

.shelly-firmware-campaign-detail-dialog {
	width: 80%;
	table {
		width: 100%;
		tr > th:nth-child(1) {
			width: 230px;
		}
		tr > th:nth-child(3) {
			width: 230px;
		}
	}
}
.shelly-firmware-campaign-event-logs {
	width: 80%;
	table {
		width: 100%;
		tr > th:nth-child(1) {
			width: 250px;
		}
		tr > th:nth-child(3) {
			width: 220px;
		}
	}
}

.payload-template {
	max-height: 8em;
	padding-right: 8px;
	overflow-y: scroll;
	word-break: break-all;

	.copy-icon {
		float: right;
		margin-right: 12px;
	}
}

.payload-template::-webkit-scrollbar {
	width: 2px;
}

.payload-template::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.payload-template::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}
