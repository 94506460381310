.event-selection{
    display: flex;
    align-items: center;
}

.event-selection div{
    margin-right: 15px;
}

.preserve-whitespace {
    white-space: pre-wrap;
}