.snow-wrapper-container {
    padding: 24px;
    // min-width: 100%;

    .snow-wrapper-action {
        margin-bottom: 16px;

        .input-field {
            display: flex;
            align-items: center;
            margin: 4px 0;
    
            label {
                width: 180px;
                align-items: center;
            }
    
            .p-password, .p-dropdown {
                margin-left: 20px;
                width: 220px;
            }
            
            .p-multiselect, .p-calendar {
                margin-left: 20px;
                width: 248px;
            }
        }

    }

    table {
        width: 100%;

        tr > th:nth-child(3) {
            width: 108px;
        }

        tr > th:nth-child(8) {
            width: 92px;
        }

        tr > td:nth-child(8) {
            text-align: center;
        }
    }

    td {
        word-wrap: break-word;
    }
}

.snow-wrapper-detail-dialog {
    width: 96%;

    h5 {
        margin: 12px 24px;
        
    }

    pre {
        margin: 8px 32px;
        white-space: pre-line;
    }
}