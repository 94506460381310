.ethernet-config-container {
    padding: 24px 0;
    width: 100%;

    .input-field {
        display: flex;
        align-items: center;
        margin: 4px 0;

        label {
            width: 180px;
            align-items: center;
        }

        .p-password,
        .p-dropdown {
            margin-left: 20px;
            width: 220px;
        }
    }

    .input-field > .p-inputtext {
        margin-left: 20px;
        width: 220px;
    }

    .error-field {
        border: 1px solid red;
        border-radius: 3px;
    }

    .ethernet-config-details {
        border: 1px solid #495057;
        margin-top: 2rem;
        padding: 1rem;

        h4 {
            width: fit-content;
            margin-top: -2rem;
            margin-left: 0.5rem;
            padding: 0 0.8rem;
            background-color: white;
        }
    }
}
