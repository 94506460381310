
  .modalpop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
    backdrop-filter: blur(5px)!important; /* apply blur filter */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  
  .modalpop-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 50%;
  }
  
  .modalpop h2 {
    margin-top: 0;
  }
  
  .modalpop p {
    margin-bottom: 20px;
  }
  
  .modalpop button {
    width: 50%;
    padding: 10px 20px;
    /* margin-right: 10px; */
    border: none;
    border-radius: 4px;
  
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  // .modalpop button:hover {
  //   background-color: #45a049;
  // }
  
  .modalpop button:last-child {
    margin-right: 0;
  }
  button.btn.btn-danger{
      background-color: red;
  }
//   button.btn.btn-primary{
//     background-color: blue;
// }
.modalpop button.btn.btn-primary:hover {
  background: blue!important;
}