.sidebar-item-expanded-container {
    margin: 1rem 0;

    .sidebar-item-expanded-label {
        height: 2.8rem;
        margin: 0.75rem 0.25rem;
        padding: 0.5rem 0.3125rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #0053a0;
        font-size: 1rem;
        font-weight: 600;
    }

    .sidebar-item-expanded-label.active {
        background-color: #0053a0;
        color: #fff;
        border-radius: 0.5rem;
    }

    .expanded-list-item {
        margin: 0.5rem 0.5rem 0.5rem 1.125rem;
        padding-left: 0.75rem;
        border-left: 2px solid #93c2e5;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 0.875rem;
        font-weight: 600;

        a {
            padding: 0.5rem;
            color: #1068bb;
        }

        a.active {
            background-color: #00a2e2;
            color: #fff;
            border-radius: 0.5rem;
        }
    }
}
