.incident-config-container {
    padding: 24px;
    width: 100%;

    .dropdown-container {
        display: flex;
        justify-content: center;
        margin: 1rem 0;

       .usecase-dropdown {
            margin: 0 4rem;
        }

        .p-dropdown {
            width: 10rem;
            margin-left: 0.6rem;
        }
    }

    .incident-config-main {

        display: flex;

        .store-container {
            width: 50%;
            max-height: 65vh;
            overflow-y: auto; 
            margin-right: 1rem;

            .store-container-top {
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;

                .update-store-selection {
                    margin: 8px 0;
    
                    button {
                        font-size: .85rem;
                        padding: 0.5rem;
                    }
    
                    button:nth-child(2) {
                        margin-left: 1rem;
                    }
                }

                .legend-container {

                    margin-left: auto;
                    margin-right: 44px;
                    margin-bottom: 4px;
                
                    .legend {
                        display:inline-block;
                        width: 12px;
                        height: 12px;
                        margin: 0 4px 0 8px;
                    }   
                }
            }
        }

        .model-container {

            width: 50%;
            max-height: 65vh;
            overflow-y: auto;

            table {
                width: 100%;
                margin-bottom: 1rem;

                .no-model-message {
                    font-size: 1rem;
                    text-align: center;
                }

                tr {
                    border-bottom: 1px solid #C1C1C1;
                }
    
    
                th,td {
                    padding: 8px;
                }
    
                td {
                    font-size: .8rem;
    
                    .p-inputswitch {
                        margin-top: 4px;
                        margin-left: 1rem;
                        height: 1.5rem;
                    }
                }
            }
        }    
    }

    .submit-btn {
        width: 188px;
        margin-top: 1rem;
        margin-left: calc(50% - 94px);
    }
}

.store-btn {
    margin: .1rem;
    padding: .25rem 0.5rem;
    font-size: .7rem;
}

.enabled-store {
    background: #22C55E !important;
    border: 1px solid #22C55E !important;
}

.disabled-store {
    background: #a1a3a7 !important;
    border: 1px solid #a1a3a7 !important;
}

/* Media Queries */

@media screen and (max-width: 960px) {

    .incident-config-container .dropdown-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .incident-config-container .dropdown-container .p-dropdown {
        margin-bottom: 1rem;
        margin-left: 7rem;
    }

    .incident-config-container .dropdown-container .usecase-dropdown {
        margin: 0;
    }

    .incident-config-container .incident-config-main {
        display: inline-block;
    }

    .incident-config-container .incident-config-main .store-container {
        width: auto;
        margin-bottom: 1rem;
    }

    .incident-config-container .incident-config-main .model-container {
        width: auto;
        margin-right: 1rem;
        margin-top: 1rem;
    }

}