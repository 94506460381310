#no-selection-message {
    color: red;
}

.chip-container {
    margin: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
