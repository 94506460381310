@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito Sans'), url(./fonts/Nunito_Sans/NunitoSans-Regular.ttf) format('truetype');
  font-display: swap;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: Nunito Sans, sans-serif !important;
  overscroll-behavior: none;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: Nunito Sans, sans-serif !important;
}

.p-paginator{
  
    justify-content: flex-end !important; 
}

