.shelly-replacement-container {
    padding: 24px;
    width: 100%;

    .link-container {
        margin-top: 20px;
    }

    .input-field {
        display: flex;
        align-items: center;
        margin: 4px 0;

        label {
            width: 180px;
            align-items: center;
        }

        .p-password, .p-dropdown {
            margin-left: 20px;
            width: 220px;
        }
    }

    .input-field > .p-inputtext,  .input-field > .p-calendar {
        margin-left: 20px;
        width: 220px;
    }

    .error-field {
        border: 1px solid red;
        border-radius: 3px;
    }

    button {
        margin-top: 8px;
        margin-bottom: 24px;
    }

    .otp-message {
        padding: 8px 20px;
        font-weight: 600;
        border: 2px solid #00529F;
        word-break: break-all;

        .value {
            font-weight: 500;
            color: #00529F;
        }
    }
}