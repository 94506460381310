.card {
    background: var(--surface-e);
    padding: 1.5rem;
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    border-radius: 4px;
    margin-bottom: 2rem;
}

.p-component {
    font-family: Nunito Sans, sans-serif !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #ffffff !important;
    border-color: #2196F3 !important;
    color: #2196F3 !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #00529F;
    border-color: #00529F;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #C1C1C1;
    border-color: #C1C1C1;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
    text-decoration: none;
}

/* .card .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    color: #C1C1C1;
    border-color: #C1C1C1;
} */

.dashboard-container {
    display: flex;

    .sidebar  {
        position: fixed;
    } 

    .sidebar +div{
        margin-left: 14rem;
    }

    .sidebar.sidebar-collapsed +div{
        margin-left: 4.5rem;
    }
}
  

