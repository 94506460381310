.command-and-control-container {
    padding: 24px;
    width: 100%;

    .p-button.p-button-success.p-button-text,
    .p-button.p-button-success.p-button-text:enabled:hover {
        color: #22C55E;
    }

    .legend-container {
        display: flex;
        justify-content: space-between;
        width: 600px;
        margin-bottom: 8px;

        button {
            margin-bottom: -6px;
        }
    }

    .cnc-refresh-icon {
        margin-top: -36px;
        float: right;
    }

    table {

        tr {

            white-space: pre-line;

            th:nth-child(1) {
                width: 88px;
            }

            th:nth-child(4) {
                width: 184px;
            }
        
            th:nth-child(5) {
                min-width: 120px;
            }

            th:nth-child(7) {
                width: 124px;
            }

            td:nth-child(1), td:nth-child(7) {
                text-align: center;
            }

            td {
                button {
                    height: 1rem !important;
                    width: 1.2rem !important;
                }
    
                button:nth-child(2) {
                    margin-left: 5px;
                }
                
                button:nth-child(3) {
                    margin: 0 10px;
                }
            }
        }
    }
}

.command-history-dialog-container {
    width: 80%;
}

/* Media Queries */

@media screen and (max-width: 960px) {

    .command-and-control-container .legend-container {
        flex-direction: column;
        align-items: flex-start;
        width: 450px;    
    }

}