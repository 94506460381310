.ga-remote-command-job-details-container {
	.summary-action {
		width: 100%;
		margin-bottom: 16px;

		.input-field {
			display: flex;
			align-items: center;
			margin: 4px 0;

			label {
				width: 180px;
				align-items: center;
			}
		}

		.input-field > .p-calendar {
			margin-left: 20px;
			width: 248px;
		}
	}

	.ga-remote-command-action-icons {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	table {
		width: 100%;

		tr > th:nth-child(2) {
			width: 90px;
		}
		tr > th:nth-child(3) {
			width: 90px;
		}
		tr > th:nth-child(4) {
			width: 80px;
		}
		tr > th:nth-child(5) {
			width: 90px;
		}
		tr > th:nth-child(6) {
			width: 100px;
		}
		tr > th:nth-child(7) {
			width: 100px;
		}
		tr > th:nth-child(8) {
			width: 100px;
		}
		tr > th:nth-child(9) {
			//width: 9rem;
			width: 90px;
		}
		tr > th:nth-child(10) {
			width: 130px;
		}
		tr > th:nth-child(11) {
			width: 7rem;
		}
		tr > th:last-child {
			//width: 8rem;
			width: 90px;
		}

		tr > td:nth-child(12) {
			width: 8rem;
			padding: 0;
			text-align: center;
		}
	}

	td {
		word-wrap: break-word;
	}
}

.p-confirm-popup {
	width: 400px;
}

.ga-remote-command-detail-dialog {
	width: 40%;
}

.sub-job-details {
	display: inline-table;
	.p-datatable {
		// max-height: 20rem;
		min-width: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.p-datatable .p-datatable-thead > tr > th {
		background: #9797974d !important;
		border: 1px solid #bbbbbb !important;
		color: #343a40;
		font-size: 11px;
		padding: 10px;
	}

	.p-datatable tr > td {
		color: #444444;
		font-size: 12px;
		border: 1px solid #bbbbbb !important;
		padding: 10px;
	}

	.p-datatable-wrapper::-webkit-scrollbar {
		width: 0.325rem;
	}

	.p-datatable-wrapper::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	.p-datatable-wrapper::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		outline: 1px solid slategrey;
	}

	table {
		width: 100%;

		tr > th:nth-child(1) {
			width: 100px;
		}
		tr > th:nth-child(2) {
			width: 100px;
		}
		tr > th:nth-child(3) {
			width: 100px;
		}
		tr > th:nth-child(4) {
			width: 100px;
		}
		tr > th:nth-child(5) {
			width: 8rem;
		}
		tr > th:nth-child(6) {
			width: 8rem;
		}
	}
	td {
		word-wrap: break-word;
	}
}

.tooltip-container {
	// display: flex;
	// position: relative;
	// align-items: left;
	// justify-content: left;
	// margin-left: 0.5rem;
	cursor: pointer;
}
.ellipsis-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	display: inline-block;
}
.tooltip-text {
	display: none;
	position: absolute;
	// background-color: #f9f9f9;
	background-color: #000;
	// color: #000;
	color: white;
	border: 1px solid #000;
	padding: 10px;
	z-index: 1;
	font-size: 12px;
	border-radius: 5px;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
	// width: 200px;
	text-align: left;
	// bottom: 30%;
	// left: 50%;
	// transform: translate(-50%);
}

.tooltip-container:hover .tooltip-text {
	display: block;
}
