.ncm-update-manage-container {
    padding: 24px 0;
    width: 100%;

    .request-type-container {

        margin-bottom: 1rem;

        label {
            width: 180px;
            align-items: center;
        }

        .radio-btn-containers {
            display: inline-flex;
            margin-left: 20px;

            label {
                margin-bottom: -6px;
                margin-left: 4px;
                padding-top: 2px;
            }
        }
    }

    .request-type-container>label {
        font-weight: 700;
    }
}

.cp-deployment-form-container {

    .flex-container {
        display: flex;
        justify-content: space-between;
        width: 80%;
    }

    .input-field {
        display: flex;
        align-items: center;
        margin: 0 8px 8px 0;

        label {
            width: 180px;
            align-items: center;
        }

        .p-dropdown {
            margin-left: 20px;
            width: 220px;
        }
    }

    .input-field > .p-inputtext {
        margin-left: 20px;
        width: 220px;
    }

    .ethernet-config-div {
        .title {
            padding-bottom: 10px;
            font-weight: 700;

            button {
                margin-bottom: -8px;
            }
        }
    }

    .error-field {
        border: 1px solid red;
        border-radius: 3px;
    }
}

/* Media Queries */

@media screen and (max-width: 960px) {

    .ncm-update-manage-container .request-type-container {
        display: flex;
    }

    .ncm-update-manage-container .request-type-container .radio-btn-containers {
        display: inline-block;
    }
    
    .cp-deployment-form-container .flex-container {
        flex-wrap: wrap;
    }

}