.automation-logs-container {
    padding: 24px;
    width: 100%;

    .automation-logs-action {
        margin-bottom: 16px;

        .input-field {
            display: flex;
            align-items: center;
            margin: 4px 0;
    
            label {
                width: 180px;
                align-items: center;
            }
    
            .p-multiselect, .p-calendar {
                margin-left: 20px;
                width: 248px;
            }
        }
    }

    table {
        width: 100%;

        tr > th:nth-child(1) {
            width: 104px
        }

        tr > th:nth-child(7) {
            min-width: 160px;
        }

        tr > th:nth-child(9) {
            width: 118px
        }

        tr > th:nth-child(10) {
            width: 72px;
        }

        tr > td:nth-child(6), tr > td:nth-child(10) {
            text-align: center;
        }
    }
}

.automation-logs-detail-dialog {
    width: 96%;

    table {
        width: 100%;
        overflow-x: scroll;

        tr>th {
            width: 140px;
        }

        tr>th:nth-child(8), tr>th:nth-child(9) {
            width: 220px;
        }
    }

    .automation-logs-detail-dialog-table-action{
    display: flex;
    justify-content: flex-end;
    }
}

.payload-template {
    max-height: 8em;
    padding-right: 8px;
    overflow-y: scroll;
    word-break: break-all;

    .copy-icon {
        float: right;
        margin-right: 12px;
    }
}

.payload-template::-webkit-scrollbar {
    width: 2px;
}

.payload-template::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.payload-template::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

/* Media Queries*/

@media screen and (max-width: 900px) {

    .automation-logs-container .automation-logs-action .input-field > .p-multiselect, .automation-logs-container .automation-logs-action .input-field > .p-calendar {
        margin-left: 0px;  
    }

}
