.incident-summary-container {
    width: 100%;

    tr > th,
    tr > td {
        padding: 0.75rem !important;
    }

    tr > th:nth-child(1),
    tr > th:nth-child(2) {
        max-width: 108px;
    }

    tr > th:nth-child(5) {
        max-width: 132px;
    }

    tr > th:nth-child(4),
    tr > th:nth-child(7),
    tr > th:nth-child(10) {
        max-width: 100px;
    }

    tr > th:last-child {
        max-width: 60px;
    }
    tr > td:last-child {
        text-align: center;
    }
}
