.pingprobe-ping-details-container {

    .summary-action {
        width: 100%;
        margin-bottom: 16px;

        .input-field {
            display: flex;
            align-items: center;
            margin: 4px 0;

            label {
                width: 180px;
                align-items: center;
            }
        }

        .input-field>.p-calendar {
            margin-left: 20px;
            width: 248px;
        }

    }

    table {
        width: 100%;

        tr>th:nth-child(2) {
            width: 10rem
        }

        tr>th:nth-child(3) {
            width: 20rem
        }

        tr>th:nth-child(4) {
            width: 6rem
        }

        tr>th:nth-child(5) {
            width: 8rem
        }

        tr>th:nth-child(6) {
            width: 8rem
        }


    }

    td {
        word-wrap: break-word;
    }



}

.sub-request-details {
    .p-datatable .p-datatable-thead > tr > th {
        background: #9797974d !important;
        border: 1px solid #bbbbbb !important;
        color: #343a40;
        font-size: 11px;
        padding: 10px;
      }
    
      .p-datatable tr > td {
        color: #444444;
        font-size: 12px;
        border: 1px solid #bbbbbb !important;
        padding: 10px;
      }

    table {
        width: 100%;

        tr>th:nth-child(1) {
            width: 4rem
        }

        tr>th:nth-child(2) {
            width: 4rem
        }

        tr>th:nth-child(3) {
            width: 4rem
        }


    }

}