.meraki-firewall-file-compare-container {
    .input-field {
        margin-bottom: 1rem;

        label {
            font-weight: 500;
            line-height: 2rem;
            margin-right: 1rem;
         }

         & > div {
            padding: 0;
            margin-top: 0.25rem;
         }

         & > div:nth-child(2) {
            display: flex;
            gap: 1.5rem;
            margin-bottom: 0.75rem;

            .p-component.p-radiobutton {
                margin-right: 0.25rem;
            }

            .p-component.p-dropdown, .p-component.p-multiselect {
                width: 12rem;
                height: 2.5rem;
            }

            .p-fileupload {
                padding-right: 0.5rem;
            }

            .p-fileupload .p-fileupload-content {
                padding: 1rem;
            }

            strong {
                font-size: 0.875rem;
                color: #7f7f7f;
            }
         }

         & > button {
            margin-right: 1.25rem;
         }

    }
    table {
        tr > th:nth-child(1), tr > td:nth-child(1) {
            max-width: 100px;
        }
        tr > th:nth-child(3), tr > td:nth-child(3), tr > th:nth-child(4), tr > td:nth-child(4) {
            max-width: 100px;
        }
    }
}
.custom-file-upload {
    text-align: center;
}

.custom-file-upload img {
    width: 40px;
    height: 20px;
    margin-right: 10px;
}
