.subtask-detail-logs-content{
    width: 100%;
    .RIT-child-details-table{
        .action-icons{
            display: flex;
            justify-content: space-between;
            tr >  th:last-child{
                width: 7.25rem;
                padding: 0;
            }
        }
       
    }
   
}
.p-dialog-header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

 .p-dialog .p-dialog-header {
        border-bottom: 0 none;
        background: #ffffff;
        color: #00529F !important ;
        padding: 1.5rem;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        font-weight: 700;
 }

.modify-schedule-subtitle {
    color: #00529F !important;
    font-weight: 800;
        
 }

 .note {
    color: red;
    font-size: 14px;
 }

 .p-confirm-popup.custom-popup {
    max-width: 500px;
    max-height: 300px;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
 }


