.device-list {
    padding: 24px;
    width: 100%;
    font-family: Nunito Sans, sans-serif !important;
}

.device-list .p-datatable table {
    font-family: Nunito Sans, sans-serif !important;
}

.device-list-title {
    font-weight: bold;
}

.device-table .p-datatable .p-datatable-thead > tr > th {
    padding: 0.8rem 1rem;
    background: #E6E8F3;
    border: 1px solid #0053A0;
    border-width: 0px 1px 0px 1px;
}

.device-table .p-datatable .p-datatable-thead > tr:nth-child(2) > th {
    padding-top: 0;
}

.device-table .p-datatable .p-datatable-thead > tr:nth-child(2) > th > input {
    width: 100%;
    height: 30px;
}

.device-table .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem 1rem;
    border: 1px solid #0053A0;
    border-width: 0px 1px 0px 1px;
}

.device-table .p-datatable .p-datatable-tbody > tr:nth-child(even) > td {
    background: #F7F9FA;
}

.device-table .p-datatable .p-selectable-row {
    cursor: default;
    color: unset;
    background-color: transparent !important;
}

.device-name-column {
    color: dodgerblue;
    cursor: pointer;
    font-weight: 500;
}

.device-name-column:hover {
    text-decoration: underline !important;
}

.grey-bg{
    background: #F3F5F6 !important;
}

.command-dropdown {
    width: 500px;
}

.invoke-button {
    float: right;
    bottom: 10px;
}

.result-message {
    margin-top: 80px;
}

.result-message div {
    background: #ffffff;
    height: 60px;
    padding: 15px;
    margin-top: 10px;
}

.fileUpload {
    padding-top: 30px;
}

.payload-label {
    font-weight: 500;
}

.upload-file-label {
    padding-top: 5px;
    padding-bottom: 10px;
}

.file-upload-input {
    width: 500px;
}

.log-date-label {
    margin-top: 40px;
}

.log-date {
    width: 500px;
    padding-top: 10px;
}

.device-id {
    font-weight: bold;
}

.register {
    float: right;
}

.unregister {
    float: right;
}

.edit-icon {
    margin-left: 1rem;
    height: 2.5rem;
}

.label-text {
    font-weight: 500;
}

.warning-message {
    color: red;
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
}

.legend-div {
    display: flex;
    font-weight: 400;
    padding-bottom: 10px;
    margin-top: 10px;

    .green-legend {
        width: 12px;
        height: 12px;
        margin-right: 5px;
        display:inline-block;
        background-color: green;
    }
    
    .orange-legend {
        width: 12px;
        height: 12px;
        margin-left: 20px;
        margin-right: 5px;
        display:inline-block;
        background-color: orange;
    }
    
    .grey-legend {
        width: 12px;
        height: 12px;
        margin-left: 20px;
        margin-right: 5px;
        display:inline-block;
        background-color: darkgrey;
    }
}

.device-id a{
    cursor: pointer;
    text-decoration: underline !important;
}

.device-id i {
    margin: 0 4px;
    font-size: 14px;
}

.device-management-list-header {
    display: flex;
    justify-content: space-between;
}

#device-version {
    display: inline-block;
    padding: 0.125rem 0.375rem;
    font-size: 0.75rem;
    color: #fff;
    background-color: #0053A0;
    border-radius: 0.4375rem;
}

/* Media Queries */

@media screen and (max-width: 960px) {

    .device-management-list-header {
        display: inline-block;
        width: 100%;

        :link {
            float: right;
            margin-top: 10px;
        }
    }

    .p-datatable .p-datatable-tbody > tr > td > .p-column-title {
        color: black;
    }

    .device-name-column {
        img {
            margin-left: auto;
        }
    }
    
}

@media screen and (max-width: 1250px) {

    .legend-div {
        display: inline-block;
    }

    .legend-div .orange-legend-div {
        margin-left: -20px;
    }

    .legend-div .grey-legend-div {
        margin-left: -20px;
    }

}