.epe-logs-container {
  padding: 24px;
  width: 100%;

  .title {
    padding-bottom: 10px;
    font-weight: 700;
  }

  .epe-logs-action {
    margin-bottom: 16px;

    .input-field {
      display: flex;
      align-items: center;
      margin: 4px 0;

      label {
        width: 180px;
        align-items: center;
      }

      .p-multiselect,
      .p-calendar {
        margin-left: 20px;
        width: 248px;
      }
    }
  }

  table {
    width: 100%;

    tr > th:nth-child(9) {
      width: 60px;
    }

    tr > td:nth-child(9) {
      text-align: center;
    }
  }

  td {
    word-wrap: break-word;
  }
}

.sub-event-details {
  .p-datatable {
    max-height: 20rem;
    min-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: #9797974d !important;
    border: 1px solid #bbbbbb !important;
    color: #343a40;
    font-size: 11px;
    padding: 10px;
  }

  .p-datatable tr > td {
    color: #444444;
    font-size: 12px;
    border: 1px solid #bbbbbb !important;
    padding: 10px;
  }

  tr > th:nth-child(4) {
    width: 130px;
  }

  tr > th:nth-child(7) {
    width: 60px;
  }

  tr > td:nth-child(7) {
    text-align: center;
  }

  .p-datatable-wrapper::-webkit-scrollbar {
    width: 0.325rem;
  }

  .p-datatable-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .p-datatable-wrapper::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.epe-logs-detail-dialog {
  width: 75%;

  button:focus {
    box-shadow: 0 0 0 0;
  }
}

.event-logs {
  table {
    width: 100%;
    tr > th:last-child {
      width: 50%;
    }
  }
}

#server-name {
  display: inline-block;
  padding: 0.125rem 0.375rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: #0053a0;
  border-radius: 0.4375rem;
  margin-bottom: 0;
}
