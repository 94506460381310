.unauthorised-user-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: calc(50vh - 172px);

    #message {
        font-size: 6rem;
        font-weight: 1000;
        color: #ff7575;
    }

    p {
        font-size: 1.5rem;

        span {
            color: #00529F;
        }
    }
}