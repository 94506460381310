.message-selection{
    display: flex;
    align-items: center;
}

.message-selection div{
    margin-right: 15px;
}

table {
    tr {
        white-space: pre-line;
    }
}