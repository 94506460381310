.incident-suppression-logs-container{
    padding: 1.5rem;

    .incident-suppression-logs-content{
        .incident-suppression-logs-action{
            display: flex;
            justify-content: flex-end;
            gap: 1.25rem;
            margin-bottom: 10px;
            align-items: center;

            .status-info-text {
                display: flex;
                align-items: center;
                font-weight: 700;
                margin-right: auto;
            }
        }
        table {
            width: 100%;

            tr > th:nth-child(1), tr > th:nth-child(3), tr > th:nth-child(9) {
                width: 130px;
            }

            tr > th:nth-child(2) {
                width: 191px;
                white-space: normal;
                word-wrap: break-word;
            }

            tr > th:nth-child(4), tr > th:nth-child(5), tr > th:nth-child(8) {
                width: 110px;
            }

            tr > th:nth-child(6) {
                width: 85px;
            }

            tr > th:nth-child(7) {
                width: 170px;
            }

            tr > th:last-child {
                width: 4rem;
            }
        }

    }

}

.detail-dialog-action-buttons{
    display: flex;
    justify-content: flex-end;
}