.sidebar {
  width: 14rem;
  height: 100%;
  min-height: calc(100vh - 78px);
  position: relative;
  border-right: 2px solid #e6e8f3;
  // transition: all 0.5s ease-in-out;
  //z-index: 1000;
  background-color: #fff;

  .logo-image {
    padding-left: 0.875rem;
    display: block;
  }

  svg {
    width: 1.5rem;
    height: 1.25rem;
  }

  button {
    position: absolute;
    top: 0.5rem;
    right: 0.875rem;
  }

  button:focus {
    box-shadow: 0 0 0 0;
  }

  a {
    color: #0053a0;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }
}

.sidebar.sidebar-collapsed {
  width: 4.5rem;

  .logo-image {
    display: none;
  }

  .sidebar-links {
    margin-top: 3.125rem;
  }
}
