.store-list {
    padding: 24px;
    width: 100%;
}

.filter-button {
    height: 42px;
}

.printer-header {
    padding-top: 20px;
}

.printerLink {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #9797974D;
    border: 1px solid #BBBBBB;
    border-bottom: none;
}

.printerLink button {
    display: flex;
    align-items: center;
    outline: none;
    border: none;
    background: none;
    color: blue;
}

.printer-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
}

.printer-name div:nth-child(1) {
    font-size: 12px;
    color: #495057;
}

.printer-name div:nth-child(2) {
    font-size: 14px;
    color: #0053A0;
}

.incident {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-right: 5px;

    div {
        margin: 0px 4px 0px 4px;
        font-size: 14px;

        div {
            margin: 0;
            padding: 6px;
        }
    }

    .open-incidents {
        position: relative;
        width: 50%;
        text-align: center;
        color: white;
        background-color: red;
        border-radius: 50%;
    }

    .open-incidents:hover {
        font-weight: 600;
        background-color: transparent;
        color: red;
    }

    .open-incidents:before {
        content: "";
        float: left;
        padding-top: 100%;
    }

    .closed-incidents {
        position: relative;
        width: 50%;
        text-align: center;
        color: white;
        background-color: green;
        border-radius: 50%;
    }

    .closed-incidents:hover {
        font-weight: 600;
        background-color: transparent;
        color: green;
    }

    .closed-incidents:before {
        content: "";
        float: left;
        padding-top: 100%;
    }
}

.p-datatable .p-datatable-thead>tr>th {
    background-color: #E6E8F3;
    opacity: 0.7;
    border: 1px solid #0053A080;
    font-size: 11px;
}

.p-datatable .p-datatable-tbody>tr>td {
    border: 1px solid #0053A080;
    font-size: 12px;
}

.p-paginator {
    border-color: #0053A080;
    font-size: 12px;
}

.expand-body .p-datatable .p-datatable-thead>tr>th {
    background: #9797974D !important;
    border: 1px solid #BBBBBB !important;
    color: #343A40;
    font-size: 11px;
}

.expand-body .p-datatable tr>td {
    color: #444444;
    font-size: 12px;
    border: 1px solid #BBBBBB !important;
}

.search input {
    width: 80%;
}

.search {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;
}

.p-autocomplete, .p-autocomplete-multiple-container {
    width: 80%!important;
}

.p-autocomplete-multiple-container {
    width: 100%!important;
}

.device-incident-popup {
    width: 50vw;
}

.asset-table {
    .p-datatable-wrapper {
        max-height: 20rem;
        min-width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }

    thead {
        position: sticky;
        top: 0;
        background-color: white;
    }

    .p-datatable-wrapper::-webkit-scrollbar {
        width: 0.325rem;
    }
    
    .p-datatable-wrapper::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    }
    
    .p-datatable-wrapper::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
}

/* Media Queries */

@media screen and (max-width: 960px) {
    
    .expand-body {
        width: 100%;
    }

}