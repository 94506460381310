.meraki-firewall-automation-container {
    width: 100%;

    .meraki-firewall-automation-action {
        margin-bottom: 16px;

        .input-field {
            display: flex;
            align-items: center;
            margin: 4px 0;
      
            label {
              width: 180px;
              align-items: center;
            }
      
            .p-calendar {
              margin-left: 20px;
              width: 248px;
            }
          }
    }
}