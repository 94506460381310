.page-not-found-container {
    width: 100%;
    text-align: center;
    margin: 4rem 0;

    span {
        font-size: 6rem;
        font-weight: 1000;
        color: #c1c1c1;
    }

    p {
        font-size: 1.5rem;
    }
}